// a list of tokens by chain
import { Currency, Token } from '@x-swap-protocol/sdk-core'
import { WXDC_XDC } from '@xspswap/smart-order-router'
import { SupportedChainId } from 'constants/chains'

import {
  BTC_BSC,
  BUSD_BSC,
  CEUR_CELO,
  CMC02_CELO,
  CUSD_CELO,
  DAI,
  DAI_BSC,
  ETH_BSC,
  FXD_XDC,
  nativeOnChain,
  PORTAL_ETH_CELO,
  PORTAL_USDC_CELO,
  SRX_XDC,
  USDC_BSC,
  USDC_MAINNET,
  USDT_XDC,
  USDC_XDC,
  USDT,
  USDT_BSC,
  USPLUS_XDC,
  WBTC,
  WRAPPED_NATIVE_CURRENCY,
  XSP_XDC,
  XTT_XDC,
} from './tokens'

type ChainTokenList = {
  readonly [chainId: number]: Token[]
}

type ChainCurrencyList = {
  readonly [chainId: number]: Currency[]
}

const WRAPPED_NATIVE_CURRENCIES_ONLY: ChainTokenList = Object.fromEntries(
  Object.entries(WRAPPED_NATIVE_CURRENCY)
    .map(([key, value]) => [key, [value]])
    .filter(Boolean)
)

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WRAPPED_NATIVE_CURRENCIES_ONLY,
  [SupportedChainId.MAINNET]: [],
  [SupportedChainId.OPTIMISM]: [...WRAPPED_NATIVE_CURRENCIES_ONLY[SupportedChainId.OPTIMISM]],
  [SupportedChainId.ARBITRUM_ONE]: [...WRAPPED_NATIVE_CURRENCIES_ONLY[SupportedChainId.ARBITRUM_ONE]],
  [SupportedChainId.POLYGON]: [...WRAPPED_NATIVE_CURRENCIES_ONLY[SupportedChainId.POLYGON]],
  [SupportedChainId.BNB]: [...WRAPPED_NATIVE_CURRENCIES_ONLY[SupportedChainId.BNB]],
  [SupportedChainId.CELO]: [CUSD_CELO, CEUR_CELO, CMC02_CELO, PORTAL_USDC_CELO, PORTAL_ETH_CELO],
  [SupportedChainId.XDC]: [...WRAPPED_NATIVE_CURRENCIES_ONLY[SupportedChainId.XDC], XSP_XDC, XTT_XDC],
  [SupportedChainId.XDC_TESTNET]: [...WRAPPED_NATIVE_CURRENCIES_ONLY[SupportedChainId.XDC_TESTNET]],
}
export const ADDITIONAL_BASES: { [chainId: number]: { [tokenAddress: string]: Token[] } } = {}
/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId: number]: { [tokenAddress: string]: Token[] } } = {}

/**
 * Shows up in the currency select for swap and add liquidity
 */
export const COMMON_BASES: ChainCurrencyList = {
  [SupportedChainId.XDC]: [
    nativeOnChain(SupportedChainId.XDC),
    XSP_XDC,
    XTT_XDC,
    WRAPPED_NATIVE_CURRENCY[SupportedChainId.XDC] as Token,
    USDC_XDC,
    FXD_XDC,
  ],
  [SupportedChainId.XDC_TESTNET]: [
    nativeOnChain(SupportedChainId.XDC_TESTNET),
    WRAPPED_NATIVE_CURRENCY[SupportedChainId.XDC_TESTNET] as Token,
  ],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WRAPPED_NATIVE_CURRENCIES_ONLY,
  [SupportedChainId.MAINNET]: [DAI, USDC_MAINNET, USDT, WBTC],
  [SupportedChainId.BNB]: [
    ...WRAPPED_NATIVE_CURRENCIES_ONLY[SupportedChainId.BNB],
    DAI_BSC,
    USDC_BSC,
    USDT_BSC,
    BTC_BSC,
    BUSD_BSC,
    ETH_BSC,
  ],
  [SupportedChainId.XDC]: [
    ...WRAPPED_NATIVE_CURRENCIES_ONLY[SupportedChainId.XDC],
    XSP_XDC,
    XTT_XDC,
    USDT_XDC,
    SRX_XDC,
    USPLUS_XDC,
  ],
  [SupportedChainId.XDC_TESTNET]: [...WRAPPED_NATIVE_CURRENCIES_ONLY[SupportedChainId.XDC_TESTNET]],
}
export const PINNED_PAIRS: { readonly [chainId: number]: [Token, Token][] } = {
  [SupportedChainId.XDC]: [[XSP_XDC, WXDC_XDC]],
  [SupportedChainId.MAINNET]: [
    [
      new Token(SupportedChainId.MAINNET, '0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643', 8, 'cDAI', 'Compound Dai'),
      new Token(
        SupportedChainId.MAINNET,
        '0x39AA39c021dfbaE8faC545936693aC917d5E7563',
        8,
        'cUSDC',
        'Compound USD Coin'
      ),
    ],
    [USDC_MAINNET, USDT],
    [DAI, USDT],
  ],
}
